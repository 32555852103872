<template>
  <form-slot-layout title="광고 영역 크기">
    <b-row>
      <b-col cols="6">
        <div v-if="disable">
          <b-form-input
            size="lg"
            disabled
          />
        </div>
        <validation-provider
          v-else
          #default="{ errors }"
          name="넓이"
          rules="required|size"
        >
          <b-input-group
            prepend="WIDTH"
            class="input-group-merge"
          >
            <b-form-input
              id="width-input"
              v-model="width"
              style="text-align: end"
              type="number"
              size="lg"
              placeholder="0"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col cols="6">
        <div
          v-if="disable"
        >
          <b-form-input
            size="lg"
            disabled
          />
        </div>
        <validation-provider
          v-else
          #default="{ errors }"
          name="높이"
          rules="required|size"
        >
          <b-input-group
            prepend="HEIGHT"
            class="input-group-merge"
          >
            <b-form-input
              id="height-input"
              v-model="height"
              style="text-align: end"
              type="number"
              size="lg"
              placeholder="0"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
    </b-row>

  </form-slot-layout>
</template>

<script>
import {
  BRow, BCol, BInputGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { computed, inject } from '@vue/composition-api'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    FormSlotLayout,
    BCol,
    BRow,
    BInputGroup,
    BFormInput,
    ValidationProvider,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      width, height, layout,
    } = inject('currentSlot')
    const disable = computed(() => props.readOnly || layout.value === 'SEARCH')

    return {
      width,
      height,
      disable,
      required,
    }
  },
}
</script>
