<template>
  <b-card-body>
    <div class="status-header">
      <p
        v-if="showingStatus.icon"
        class="status-label"
      >
        지면상태 :
        <b-icon
          v-if="showingStatus.icon"
          class="status-icon"
          scale="1.5"
          :icon="showingStatus.icon"
          :variant="showingStatus.variant"
        />
      </p>
      <p
        v-else
        class="status-label"
      >
        지면상태 : {{ showingStatus.text }}
      </p>
    </div>
    <div
      v-if="isPending"
      class="reject-commentary"
    >
      <b-form-group
        label-size="lg"
        label="반려사유"
        label-for="reject-commentary"
      >
        <b-form-checkbox-group
          v-model="category"
          :options="categoryOptions"
          class="mb-1 text-nowrap"
        />
        <b-form-textarea
          id="reject-commentary"
          v-model="description"
          rows="4"
          placeholder="반려사유가 있다면 입력해주세요."
        />
      </b-form-group>
    </div>
    <div
      v-if="hasRejectHistory"
      class="reject-commentary"
    >
      <b-form-group
        label-size="lg"
        label="지난 반려 사유"
        label-for="reject-commentary"
      >
        <b-form-textarea
          id="reject-commentary"
          v-model="rejectHistories[0].comment"
          class="text-danger"
          rows="4"
          :state="false"
          plaintext
        />
      </b-form-group>
    </div>
  </b-card-body>
</template>

<script>
import {
  BIcon, BFormGroup, BFormTextarea, BCardBody,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import {
  computed, inject,
} from '@vue/composition-api'
import useStatusResolver from '@/views/utils/useStatusResolver'

export default {
  components: {
    BCardBody,
    BIcon,
    BFormGroup,
    BFormTextarea,
    BFormCheckboxGroup,
  },
  setup() {
    const { resolveSlotStatus, resolveReviewStatus } = useStatusResolver()
    const { status, reviewStatus, rejectHistories } = inject('currentSlot')
    const { category, description } = inject('newRejectHistory')

    const isPending = computed(() => reviewStatus.value === 'PENDING')
    const showingStatus = computed(() => {
      if (reviewStatus.value === 'ACCEPTED') {
        return resolveSlotStatus(status.value)
      }
      return resolveReviewStatus(reviewStatus.value)
    })

    const categoryOptions = [
      { text: '광고 영역 크기', value: '광고 영역 크기' },
      { text: '지면 위치', value: '지면 위치' },
      { text: '지면 가이드 내용', value: '지면 가이드 내용' },
      { text: '스크립트', value: '스크립트' },
    ]

    const hasRejectHistory = computed(() => rejectHistories.value.length > 0)
    return {
      showingStatus,
      hasRejectHistory,
      rejectHistories,
      category,
      categoryOptions,
      description,
      isPending,
    }
  },
}
</script>

<style lang="scss">
.status-header {
  display: flex;
  flex-direction: row;

  .status-label {
    font-size: 20px;
    color: white;
    margin-left: auto;
  }

  .status-icon {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.pause-info {
  margin-right: 20px;
}
</style>
