<template>
  <form-slot-layout title="지면 운영 기간">
    <b-row align-h="center">
      <b-col cols="6">
        <div
          class="d-flex align-items-center"
        >
          <span class="text-nowrap mr-1">
            시작
          </span>
          <flat-pickr
            v-model="openAt"
            :config="configStart"
            :disabled="readOnly"
            class="form-control"
          />
        </div>
      </b-col>
      <b-col cols="6">
        <div class="d-flex align-items-center">
          <span class="text-nowrap mr-1">
            종료
          </span>
          <flat-pickr
            v-if="isInfinite"
            :value="closeAt"
            disabled
            class="form-control"
          />
          <flat-pickr
            v-else
            v-model="closeAt"
            :config="configEnd"
            :disabled="readOnly"
            class="form-control"
          />
        </div>
        <div class="d-flex justify-content-end">
          <b-form-checkbox
            v-model="isInfinite"
            :disabled="readOnly"
            class="infinite-check"
          >
            <p class="type-label">
              무기한
            </p>
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
  </form-slot-layout>

</template>

<script>
import {
  BCol, BRow, BFormCheckbox,
} from 'bootstrap-vue'
import {
  computed,
  inject,
} from '@vue/composition-api'
import { Korean } from 'flatpickr/dist/l10n/ko'
import flatPickr from 'vue-flatpickr-component'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    FormSlotLayout,
    flatPickr,
    BCol,
    BRow,
    BFormCheckbox,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { openAt, closeAt } = inject('currentSlot')
    const isInfinite = computed({
      get() {
        return closeAt.value === null
      },
      set(value) {
        if (value) {
          closeAt.value = null
        } else if (closeAt.value === null) {
          const startDate = new Date(Date.parse(openAt.value))
          const year = startDate.getFullYear()
          const month = startDate.getMonth() + 1
          const date = startDate.getDate() + 7
          closeAt.value = [year, month > 9 ? month : `0${month}`, date > 9 ? date : `0${date}`].join('-')
        }
        return value
      },
    })

    const configStart = computed(() => ({
      wrap: false,
      mode: 'single',
      locale: Korean,
      disable: [
        function (date) {
          // return true to disable
          const today = new Date(Date.parse(openAt.value))
          return date < new Date(today.getFullYear(), today.getMonth(), today.getDate())
        },
      ],
    }))

    const configEnd = computed(() => ({
      wrap: false,
      mode: 'single',
      locale: Korean,
      disable: [
        function (date) {
          // return true to disable
          return date < new Date(Date.parse(openAt.value))
        },
      ],
    }))

    return {
      openAt,
      closeAt,
      isInfinite,
      configStart,
      configEnd,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
.form-control:disabled {
  cursor: auto;
}
.date-prepend {
  margin-right: 15px;
}

.infinite-check {
  margin-top: 20px;
}
</style>
