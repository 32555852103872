<template>
  <form-slot-layout
    title="지면 위치"
    guide-id="popover-attachment"
  >
    <guide-popover
      target="popover-attachment"
      title="지면 위치 가이드"
    >
      지면이 포함된 화면 전체를 캡쳐하여 등록하실 지면에 표시해주세요.<br><br>
      해당 캡쳐 이미지는 광고주들이 캠페인을 신청할 때 지면의 형태나 위치에 대한 정보를 제공하기 위해 활용됩니다.<br><br><br>
      이미지 권장 사양 : 최소 1000 * 300 px<br>
      파일 형식 : JPEG, PNG<br>
      파일 크기 : 30MB 이내<br>
    </guide-popover>
    <div class="d-flex flex-column">
      <b-form-input
        v-model="imageSource"
        size="lg"
        disabled
      />
      <div
        v-if="disable"
        class="sxa-image-preview"
      />
      <b-img
        v-else
        contain
        :src="imageSource"
        class="sxa-image-preview"
        style="cursor: pointer"
        @click="showModal"
      />
      <!-- image modal -->
      <b-modal
        id="modal-image-preview"
        title=""
        ok-title="다운로드"
        cancel-title="닫기"
        ok-variant="primary"
        hide-header-close
        centered
        @ok="downloadImage"
      >
        <b-card
          no-body
          :img-src="imageSource"
          size="xl"
          img-alt="Image"
        />
      </b-modal>
    </div>
  </form-slot-layout>
</template>

<script>
import {
  BFormInput, VBModal, BModal, BCard, BImg,
} from 'bootstrap-vue'
import {
  computed, inject,
} from '@vue/composition-api'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    BFormInput,
    FormSlotLayout,
    BModal,
    BCard,
    BImg,
  },
  directives: {
    'b-modal': VBModal,
  },
  methods: {
    showModal() {
      this.$bvModal.show('modal-image-preview')
    },
    async downloadImage() {
      await this.download()
      this.$bvModal.hide('modal-image-preview')
    },
  },
  setup() {
    const {
      imageSource, layout,
    } = inject('currentSlot')
    const disable = computed(() => layout.value === 'SEARCH')
    const download = () => {

    }
    return {
      imageSource,
      disable,
      download,
    }
  },
}
</script>
<style lang="scss">
.sxa-image-preview {
  width: 180px;
  height: 120px;
  background-color: var(--secondary);
  opacity: 0.4;
  margin-top: 15px;
}
</style>
