<template>
  <form-slot-layout
    title="과금 방식 및 금액"
    guide-id="popover-charge"
  >
    <guide-popover
      target="popover-charge"
      title="과금 방식 및 금액 가이드"
    >
      하나의 지면에 CPM, CPC <strong>중복 선택</strong>이 가능합니다.<br><br>
      CPM : 고객에게 노출되는 만큼 과금됩니다. 100만 노출당 과금 금액을 적어주세요.<br><br>
      CPC : 고객이 해당 캠페인을 클릭하여 방문했을 때 과금됩니다. 1번 클릭당 과금 금액을 적어주세요.<br><br>
    </guide-popover>
    <small class="text-danger">{{ invalidFeedback }}</small>
    <div
      v-for="item in chargeOptions"
      :key="item.text"
      class="charge-type-row"
    >
      <b-form-checkbox
        v-model="item.isOn"
        class="type-label"
        :disabled="readOnly||item.disable"
      >
        <strong style="font-size: 16px">
          {{ item.text }}
        </strong>
      </b-form-checkbox>
      <b-col cols="9">
        <validation-provider
          v-if="item.isOn"
          #default="{ errors }"
          name="가격"
          rules="required|price"
        >
          <b-input-group
            append="KRW"
            class="input-group-merge"
          >
            <b-form-input
              v-model="item.price"
              style="text-align: end"
              type="number"
              size="lg"
              :disabled="readOnly"
            />
          </b-input-group>
          <small
            v-show="item.isOn"
            class="text-danger"
          >{{ errors[0] }}</small>
        </validation-provider>
        <div v-else>
          <b-input-group
            append="KRW"
            class="input-group-merge"
          >
            <b-form-input
              v-model="item.price"
              style="text-align: end"
              type="number"
              size="lg"
              disabled
            />
          </b-input-group>
        </div>
      </b-col>
    </div>

  </form-slot-layout>
</template>

<script>
import {
  VBHover, BInputGroup,
  BFormCheckbox, BFormInput, BCol,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { computed, inject } from '@vue/composition-api'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    FormSlotLayout,
    BInputGroup,
    BFormCheckbox,
    BFormInput,
    BCol,
    ValidationProvider,
  },
  directives: {
    'b-hover': VBHover,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const chargeOptions = inject('chargeOptions')
    const isPriceValid = inject('isPriceValid')

    const invalidFeedback = computed(() => {
      if (isPriceValid.value) {
        return ''
      }
      return '과금 방식을 선택해주세요.'
    })
    return {
      chargeOptions,
      invalidFeedback,
      required,
    }
  },
}
</script>

<style lang="scss">
.charge-type-row {
  display: flex;
  flex-direction: row;
  overflow: auto;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  height: 70px;

  .type-label {
    margin-right: auto;
    margin-top: 10px;
    vertical-align: middle;
  }
}
</style>
