<template>
  <form-slot-layout title="지면 가이드 내용">
    <validation-provider
      #default="{ errors }"
      name="지면 가이드"
      rules="required|description"
    >
      <b-form-textarea
        id="input-guide"
        v-model="description"
        rows="5"
        placeholder="광고주들이 지면에 광고를 등록할 때 참고할 수 있는, 지면에 대한 가이드를 적어주세요."
        trim
        :disabled="readOnly"
      />
      <small class="text-secondary d-flex justify-content-end">{{ `${textLength}/250` }}</small>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

  </form-slot-layout>

</template>

<script>
import { BFormTextarea } from 'bootstrap-vue'
import { computed, inject } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    FormSlotLayout,
    BFormTextarea,
    ValidationProvider,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { description } = inject('currentSlot')
    const textLength = computed(() => description.value.length)

    return {
      description,
      required,
      textLength,
    }
  },
}
</script>
