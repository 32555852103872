/* eslint-disable object-curly-newline */
import { ref, reactive, toRefs, computed, provide, readonly, watchEffect } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useDate from '@/views/utils/useDate'
import store from '@/store'

export default function useSlot() {
  const { t } = useI18nUtils()
  const refForm = ref(null)
  const cachedSlot = ref(null)
  const { todayString, parseDateToString, isClosed } = useDate()

  const currentSlot = toRefs(reactive({
    id: '',
    channelAccountId: store.getters['account/accountId'],
    name: '',
    device: 'DESKTOP',
    inventory: 'EXCLUSIVE',
    chargeTypeCpc: false,
    chargeAmountCpc: null,
    chargeTypeCpm: false,
    chargeAmountCpm: null,
    layout: 'IMAGE',
    openAt: todayString(),
    closeAt: null,
    imageFile: null, // image uploaded from local directory
    imageSource: null, // image uploaded at CDN. May have initial value
    description: '',
    reviewStatus: 'PENDING',
    status: 'ACTIVATED',
    width: 0,
    height: 0,
    maxLength: 0,
    rejectHistories: [],
  }))

  const makeSlotData = () => {
    const data = {
      id: currentSlot.id.value,
      channelAccountId: currentSlot.channelAccountId.value,
      name: currentSlot.name.value,
      device: currentSlot.device.value,
      inventory: currentSlot.inventory.value,
      chargeTypeCpc: currentSlot.chargeTypeCpc.value,
      chargeAmountCpc: currentSlot.chargeAmountCpc.value,
      chargeTypeCpm: currentSlot.chargeTypeCpm.value,
      chargeAmountCpm: currentSlot.chargeAmountCpm.value,
      layout: currentSlot.layout.value,
      openAt: Date.parse(currentSlot.openAt.value),
      closeAt: currentSlot.closeAt.value !== null ? Date.parse(currentSlot.closeAt.value) : null,
      imageFile: currentSlot.imageFile.value,
      imageSource: currentSlot.imageSource.value,
      description: currentSlot.description.value,
      reviewStatus: currentSlot.reviewStatus.value,
      status: currentSlot.status.value,
      width: currentSlot.width.value,
      height: currentSlot.height.value,
      maxLength: currentSlot.maxLength.value,
    }
    return data
  }

  const hasChange = ref(false)
  const hasValidatedOnce = ref(false)
  const isFileValid = computed(() => {
    if (!hasValidatedOnce.value) {
      return true
    }
    return currentSlot.layout.value === 'SEARCH'
            || currentSlot.imageFile.value || currentSlot.imageSource.value
  })

  const isPriceValid = computed(() => {
    if (!hasValidatedOnce.value) {
      return true
    }
    return currentSlot.chargeTypeCpc.value || currentSlot.chargeTypeCpm.value
  })

  const setCurrentSlot = slot => {
    currentSlot.id.value = slot.id
    currentSlot.channelAccountId.value = slot.channelAccountId
    currentSlot.name.value = slot.name
    currentSlot.device.value = slot.device
    currentSlot.inventory.value = slot.inventory
    currentSlot.chargeTypeCpc.value = slot.chargeTypeCpc
    currentSlot.chargeAmountCpc.value = slot.chargeTypeCpc ? slot.chargeAmountCpc.toString() : null
    currentSlot.chargeTypeCpm.value = slot.chargeTypeCpm
    currentSlot.chargeAmountCpm.value = slot.chargeTypeCpm ? slot.chargeAmountCpm.toString() : null
    currentSlot.layout.value = slot.layout
    currentSlot.openAt.value = parseDateToString(slot.openAt)
    currentSlot.closeAt.value = parseDateToString(slot.closeAt)
    currentSlot.description.value = slot.description
    currentSlot.reviewStatus.value = slot.reviewStatus
    currentSlot.status.value = isClosed(slot.closeAt) && slot.reviewStatus === 'ACCEPTED' ? 'CLOSED' : slot.status
    currentSlot.width.value = slot.width.toString()
    currentSlot.height.value = slot.height.toString()
    currentSlot.maxLength.value = slot.maxLength
    currentSlot.rejectHistories.value = slot.rejectHistories

    const src = slot.attachments[0]
    currentSlot.imageSource.value = src

    cachedSlot.value = JSON.stringify({
      id: currentSlot.id.value,
      channelAccountId: currentSlot.channelAccountId.value,
      name: currentSlot.name.value,
      device: currentSlot.device.value,
      inventory: currentSlot.inventory.value,
      chargeTypeCpc: currentSlot.chargeTypeCpc.value,
      chargeAmountCpc: currentSlot.chargeAmountCpc.value,
      chargeTypeCpm: currentSlot.chargeTypeCpm.value,
      chargeAmountCpm: currentSlot.chargeAmountCpm.value,
      layout: currentSlot.layout.value,
      openAt: currentSlot.openAt.value,
      closeAt: currentSlot.closeAt.value,
      imageFile: currentSlot.imageFile.value,
      imageSource: currentSlot.imageSource.value,
      description: currentSlot.description.value,
      reviewStatus: currentSlot.reviewStatus.value,
      status: currentSlot.status.value,
      width: currentSlot.width.value,
      height: currentSlot.height.value,
      maxLength: currentSlot.maxLength.value,
    })
    hasChange.value = false
  }

  const validate = () => {
    hasValidatedOnce.value = true
    return refForm.value.validate() && isFileValid.value && isPriceValid.value
  }

  watchEffect(() => {
    hasChange.value = cachedSlot.value !== JSON.stringify({
      id: currentSlot.id.value,
      channelAccountId: currentSlot.channelAccountId.value,
      name: currentSlot.name.value,
      device: currentSlot.device.value,
      inventory: currentSlot.inventory.value,
      chargeTypeCpc: currentSlot.chargeTypeCpc.value,
      chargeAmountCpc: currentSlot.chargeAmountCpc.value,
      chargeTypeCpm: currentSlot.chargeTypeCpm.value,
      chargeAmountCpm: currentSlot.chargeAmountCpm.value,
      layout: currentSlot.layout.value,
      openAt: currentSlot.openAt.value,
      closeAt: currentSlot.closeAt.value,
      imageFile: currentSlot.imageFile.value,
      imageSource: currentSlot.imageSource.value,
      description: currentSlot.description.value,
      reviewStatus: currentSlot.reviewStatus.value,
      status: currentSlot.status.value,
      width: currentSlot.width.value,
      height: currentSlot.height.value,
      maxLength: currentSlot.maxLength.value,
    })
  })

  const deviceOptions = [
    { text: t('DESKTOP'), value: 'DESKTOP' },
    { text: t('MOBILE'), value: 'MOBILE' },
  ]

  const inventoryOptions = [
    { text: t('EXCLUSIVE'), value: 'EXCLUSIVE' },
    { text: t('NETWORK'), value: 'NETWORK', disabled: true },
  ]

  const layoutOptions = [
    { text: t('IMAGE'), value: 'IMAGE' },
    { text: t('VIDEO'), value: 'VIDEO' },
    { text: t('SEARCH'), value: 'SEARCH' },
  ]

  const chargeOptions = computed(() => {
    if (!currentSlot.chargeTypeCpc.value) {
      currentSlot.chargeAmountCpc.value = null
    }
    if (!currentSlot.chargeTypeCpm.value) {
      currentSlot.chargeAmountCpm.value = null
    }

    return [
      reactive({
        text: 'CPC',
        isOn: currentSlot.chargeTypeCpc,
        price: currentSlot.chargeAmountCpc,
      }),
      reactive({
        text: 'CPM',
        isOn: currentSlot.chargeTypeCpm,
        price: currentSlot.chargeAmountCpm,
      }),
      {
        text: 'CPA',
        isOn: false,
        price: null,
        disable: true,
      },
    ]
  })

  // *===============================================---*
  // *--------- Provide --------------------------------*
  // *===============================================---*
  provide('currentSlot', currentSlot)

  // options
  provide('deviceOptions', readonly(deviceOptions))
  provide('inventoryOptions', readonly(inventoryOptions))
  provide('layoutOptions', readonly(layoutOptions))
  provide('chargeOptions', chargeOptions)
  provide('isFileValid', isFileValid)
  provide('isPriceValid', isPriceValid)
  return {
    refForm,
    validate,
    isFileValid,
    isPriceValid,
    hasChange,
    currentSlot,
    layoutOptions,
    setCurrentSlot,
    makeSlotData,
  }
}
