<template>
  <form-slot-layout
    title="최대 글자수 제한"
    guide-id="popover-max-length"
  >
    <guide-popover
      target="popover-max-length"
      title="최대 글자수 제한 가이드"
    >
      검색광고를 운영하고 싶은 경우 글자수를 최대 몇개까지 제한을 둘 것인지 적어주세요. 숫자 1~99까지만 입력할 수 있습니다. <br>
    </guide-popover>
    <validation-provider
      v-if="enable"
      #default="{ errors }"
      name="글자 수"
      rules="required|searchLength"
    >
      <b-input-group
        prepend="최대"
        append="자"
        class="input-group-merge"
      >
        <b-form-input
          id="max-input"
          v-model="maxLength"
          style="text-align: end"
          type="number"
          size="lg"
          placeholder="0"
          :disabled="readOnly"
        />
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
    <div v-else>
      <b-form-input
        size="lg"
        disabled
      />
    </div>

  </form-slot-layout>

</template>

<script>
import {
  BInputGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { computed, inject } from '@vue/composition-api'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    FormSlotLayout,
    BInputGroup,
    BFormInput,
    ValidationProvider,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { maxLength, layout } = inject('currentSlot')
    const enable = computed(() => layout.value === 'SEARCH')

    return {
      maxLength,
      enable,
      required,
    }
  },
}
</script>
