<template>
  <loading-component
    v-if="loading"
  />
  <error-component
    v-else-if="error"
    :error="error"
  />
  <app-form-layout
    v-else
  >
    <template #top>
      <slot-status-admin />
    </template>

    <template #left>
      <button-selector-form
        title="버전 구분"
        data-key="currentSlot"
        model-key="device"
        option-key="deviceOptions"
        read-only
      />
      <name-form
        type="지면"
        inject-key="currentSlot"
        read-only
      />
      <button-selector-form
        title="참여 인벤토리"
        data-key="currentSlot"
        model-key="device"
        option-key="deviceOptions"
        guide-id="popover-inventory"
        read-only
      />
      <guide-popover
        target="popover-inventory"
        title="참여 인벤토리 가이드"
      >
        단독 채널 : 광고주가 선택시 단독 채널 내에서만 광고가 송출되며, 과금 방식이나 과금 금액을 직접 설정할 수 있습니다.<br><br>
        네트워크 광고 : 등록하시는 지면이 네트워크 채널에 소속되어 광고주가 선택시 채널에 등록된 다양한 네트워크 광고 지면에 광고가 송출됩니다. 따라서 과금 방식이나 과금 금액을 직접 설정할 수는 없지만 일정 수익은 발생할 수 있습니다.
      </guide-popover>
      <slot-date-selector read-only />
      <slot-charge-selector read-only />
    </template>

    <!-- Right Col -->
    <template #right>
      <button-selector-form
        title="광고 형식"
        data-key="currentSlot"
        model-key="layout"
        option-key="layoutOptions"
        guide-id="popover-type"
        read-only
      />
      <guide-popover
        target="popover-type"
        title="광고 형식 가이드"
      >
        해당 지면에 운영되는 광고의 형식이 이미지 광고, 동영상 광고, 검색 광고인지 선택해주세요.
      </guide-popover>
      <slot-size-form read-only />
      <slot-attachment-admin />
      <slot-max-search-length read-only />
      <slot-description-form read-only />
    </template>

    <template
      v-if="isPending"
      #submit-button
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mb-2 mt-2"
        block
        :disabled="!isAcceptable"
        @click="onAccept"
      >
        승인하기
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mb-2 mt-2"
        block
        :disabled="isAcceptable"
        @click="onReject"
      >
        반려하기
      </b-button>
    </template>
  </app-form-layout>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { BButton } from 'bootstrap-vue'
import useSlot from '@/views/utils/slot/useSlot'
import {
  computed, onMounted, provide, reactive, toRefs,
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import AppFormLayout from '@/views/layouts/AppFormLayout.vue'
import SlotSizeForm from '@/views/components/slot/SlotSizeForm.vue'
import SlotAttachmentAdmin from '@/views/components/slot/SlotAttachmentAdmin.vue'
import SlotStatusAdmin from '@/views/components/slot/SlotStatusAdmin.vue'
import NameForm from '@/views/components/NameForm.vue'
import ButtonSelectorForm from '@/views/components/ButtonSelectorForm.vue'
import SlotChargeSelector from '@/views/components/slot/SlotChargeSelector.vue'
import SlotDateSelector from '@/views/components/slot/SlotDateSelector.vue'
import SlotDescriptionForm from '@/views/components/slot/SlotDescriptionForm.vue'
import SlotMaxSearchLength from '@/views/components/slot/SlotMaxSearchLength.vue'
import useAdminTask from '@/views/utils/task/useAdminTask'

export default {
  components: {
    BButton,
    AppFormLayout,
    SlotStatusAdmin,
    ButtonSelectorForm,
    NameForm,
    SlotChargeSelector,
    SlotDateSelector,
    SlotSizeForm,
    SlotAttachmentAdmin,
    SlotDescriptionForm,
    SlotMaxSearchLength,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      setCurrentSlot,
      currentSlot,
      makeSlotData,
    } = useSlot()

    const {
      loading,
      error,
      fetchSlot,
      reviewSlot,
    } = useAdminTask()

    const { route, router } = useRouter()

    const { reviewStatus } = currentSlot
    const isPending = computed(() => reviewStatus.value === 'PENDING')
    const newRejectHistory = toRefs(reactive({
      category: [],
      description: '',
    }))
    provide('newRejectHistory', newRejectHistory)

    const onAccept = async () => {
      const slot = makeSlotData()
      const review = {
        result: 'ACCEPTED',
      }
      await reviewSlot(slot, review)
      router.go(-1)
    }

    const onReject = async () => {
      const slot = makeSlotData()
      const review = {
        result: 'REJECTED',
        category: newRejectHistory.category.value.join(','),
        description: newRejectHistory.description.value,
      }
      await reviewSlot(slot, review)
      router.go(-1)
    }

    const isAcceptable = computed(() => newRejectHistory.category.value.length === 0 && !newRejectHistory.description.value)

    onMounted(async () => {
      const { slotId } = route.value.params
      const slot = await fetchSlot(slotId)
      setCurrentSlot(slot)
    })

    return {
      loading,
      error,
      onAccept,
      onReject,
      isPending,
      isAcceptable,
    }
  },
}
</script>
